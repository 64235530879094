import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { TfiSettings } from "react-icons/tfi";
import Profile from '../components/shared/profile';
import { PiUser, PiUsersThreeLight } from "react-icons/pi";
import { GoLog } from "react-icons/go";
import { SlCalender } from "react-icons/sl";
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import TopBar from './topBar';
import { GetRequest } from '../utils/request';
import { chatActions } from '../store/chat-slice';
import RecentChat from '../components/layout/recentChat';
import Projects from '../components/layout/allProjects';
import ChatSocket from '../socket/chat-socket';
import TaskSocket from "../socket/task-socket";
import { TbNotebook, TbTagStarred } from "react-icons/tb";
import Loader from '../components/custom/loader';
import Toastify from '../components/custom/toastify';

const Layout = ({ children, className = "", bgClass = "" }) => {
    const dispatch = useDispatch();
    const drawerRef = useRef(null);
    const { showDrawer, currentTheme } = useSelector((state) => state.ui);
    const { accountInfo } = useSelector((state) => state.account);
    const { isSearched, triggerChat, currentChat, loadingChat } = useSelector((state) => state.chat);

    ChatSocket.useSocketSetup();
    ChatSocket.useMessageEvent();
    ChatSocket.useNotificationEvent(currentChat);
    ChatSocket.useCallingEvent();
    ChatSocket.useShowBlockedEvent();
    ChatSocket.useMsgRequestEvent(accountInfo?.data?._id);
    TaskSocket.useTaskSetup(accountInfo?.data?._id);
    TaskSocket.useTaskAdded()
    TaskSocket.useNotificationTask()

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            dispatch(uiActions.setDrawer(false))
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/conversation/${accountInfo?.data?._id}`).then(response => {
                const sortedData = response.data.sort((a, b) =>
                    new Date(b.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)
                );
                dispatch(chatActions.setChatList(sortedData));
            }).catch(error => {
                console.log("conversation error >>>", error.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, isSearched, triggerChat])

    return (
        <div className='flex max-lg:flex-col relative overflow-hidden'>
            <Toastify />
            {/* FOR MOBILE ONLY */}
            <div className={`lg:hidden h-[100vh] bg-[#aaaaaa8a] fixed overflow-hidden overflow-y-auto w-full shadow-md z-[999] w-full ${showDrawer ? "left-[0%]" : "left-[-100%]"}`}>
                <div ref={drawerRef} className={`absolute h-[100vh] overflow-hidden overflow-y-auto w-full shadow-md bg-primary max-w-[250px] duration-700 ${showDrawer ? "left-[0%]" : "left-[-100%]"}`}>
                    <ActionBar onClick={() => dispatch(uiActions.setDrawer(false))} />
                </div>
            </div>

            {/* FOR DESKTOP ONLY */}
            <div className={`hidden lg:block h-[100vh] fixed overflow-hidden overflow-y-auto max-w-[250px] shadow-md bg-primary w-full ${currentTheme}`}>
                <ActionBar />
            </div>

            <div className={`lg:ml-[250px] md:h-[100vh] w-full overflow-hidden relative overflow-y-auto z-[99] bg-[#F5FFFA] ${bgClass}`}>
                <TopBar />
                {loadingChat && (
                    <Loader className='bg-[#F5FFFA]' loaderCss="text-[#000]" />
                )}
                <div className={`relative px-[10px] md:px-[20px] lg:px-[30px] mx-auto max-w-[1200px] ${className}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

function ActionBar(props) {
    const { pathname } = useLocation();
    const { accountInfo } = useSelector((state) => state.account);
    const role = accountInfo?.data?.role?.title?.toLowerCase();
    const permissions = accountInfo?.permissions;

    const adminNavLinks = [
        {
            title: "Employees",
            handle: "/employees",
            icon: <PiUsersThreeLight size={20} className={`hover:text-primary ${pathname === "/employees" ? "bg-active text-primary" : "text-default"}`} />,
        },
        {
            title: "Permissions",
            handle: "/permissions",
            icon: <MdOutlineAdminPanelSettings size={20} className={`hover:text-primary ${pathname === "/permissions" ? "bg-active text-primary" : "text-default"}`} />,
        },
        {
            title: "Subscription",
            handle: "/subscription",
            icon: <TbTagStarred size={20} className={`hover:text-primary ${pathname === "/subscription" ? "bg-active text-primary" : "text-default"}`} />,
        },
        {
            title: "Logs",
            handle: "/logs",
            icon: <GoLog size={20} className={`hover:text-primary ${pathname === "/logs" ? "bg-active text-primary" : "text-default"}`} />,
        }
    ]
    
    const navLinks = [
        {
            title: "Account",
            handle: "/account",
            icon: <PiUser size={20} className={`hover:text-primary ${pathname === "/account" ? "bg-active text-primary" : "text-default"}`} />,
            role: "admin"
        },
        {
            title: "Notes",
            handle: "/notes",
            icon: <TbNotebook size={20} className={`hover:text-primary ${pathname === "/notes" ? "bg-active text-primary" : "text-default"}`} />,
        },
        {
            title: "Events",
            handle: "/events",
            icon: <SlCalender size={20} className={`hover:text-primary ${pathname === "/events" ? "bg-active text-primary" : "text-default"}`} />,
            role: "other"
        }
    ]

    return (
        <>
            <Profile {...props}/>
            <hr />
            <div className='grid grid-cols-2'>
                {navLinks.map((item, i) => (
                    <Link
                        key={i}
                        to={item.handle}
                        className={`flex flex-col justify-center items-center lg:px-[10px] max-lg:px-[6px] py-[10px] cursor-pointer ${pathname === item.handle ? "bg-active text-primary" : "text-default"} `}
                        {...props}
                    >
                        {item.icon}
                        <span className='w-full text-center text-[12px]'>{item.title}</span>
                    </Link>
                ))}
                {role === "admin" && (
                    <>
                        {adminNavLinks.map((item, i) => (
                            <Link key={i} to={item.handle} className={`flex flex-col justify-center items-center lg:px-[10px] max-lg:px-[6px] py-[10px] cursor-pointer ${pathname === item.handle ? "bg-active text-primary" : "text-default"} `} {...props}>
                                {item.icon}
                                <span className='w-full text-center text-[12px] '>{item.title}</span>
                            </Link>
                        ))}
                    </>
                )}

            </div>
            <hr />
            <div className='relative overflow-hidden'>
                <RecentChat {...props} />
                <hr />
            </div>

            {(permissions?.viewProject || role === "admin") && (
                <div>
                    <Projects onClick={props.onClick} />
                    <hr />
                </div>
            )}

            {/* {role === "admin" && (
                <>
                    <Link to="/report" {...props} className={`flex justify-start items-center px-[30px] py-[20px] bg-hover cursor-pointer gap-[20px] bg-white ${pathname === "/settings" && "bg-active"}`}>
                        <GoReport size={20} />
                        <span className='text-center text-[12px]'>Report</span>
                    </Link>
                    <hr />
                </>
            )} */}
            <Link 
                to="/settings" 
                className={`flex justify-start items-center mx-[15px] px-[15px] my-[10px] py-[10px] cursor-pointer gap-[20px] rounded-full ${pathname === "/settings" ? "bg-active !text-primary border" : "text-default"}`}
                {...props} 
            >
                <TfiSettings size={20} className={pathname === "/settings" ? "text-primary" : "text-default"} />
                <span className='text-center text-[12px]'>Settings</span>
            </Link>
            <hr />
        </>
    )
};

export default Layout;