import React, { useEffect, useState, useRef } from 'react';
import ReactModal from '.';
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import Dropdown from '../components/custom/dropdown';
import Gender from '../components/shared/gender';
import { checkEmptyFields, validateEmail, validatePassword } from '../utils/formValidation';
import Error from '../components/custom/error';
import { GetRequest, PostRequest } from '../utils/request';
import { useSelector, useDispatch } from 'react-redux';
import { LiaCatSolid } from "react-icons/lia";
import ExpiredPlan from '../components/shared/expiredPlan';
import { formatDateForCompare, convertDate } from '../utils/formatTimestamp';
import { subscriptionActions } from '../store/subscription-slice';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { FaFileUpload } from "react-icons/fa";
import BulkEmployeeUpload from './bulkEmployeeUpload';
import Papa from 'papaparse';
import { FaUserTie } from "react-icons/fa";
import { RiEditFill } from "react-icons/ri";

const AddEmployee = ({ label = "", className = "", departments = [], setTrigger = () => { } }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const { accountInfo } = useSelector((state) => state.account);
    const { planTrigger, currentPlan } = useSelector((state) => state.subscription);

    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [fileError, setFileError] = useState("");
    const [roles, setRoles] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [file, setFile] = useState([]);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [success, setSuccess] = useState({
        singleUpload: "",
        bulkUpload: ""
    })

    const [formInput, setFormInput] = useState({
        fullName: "",
        email: "",
        password: "",
        gender: "prefer not to say",
        department: "",
        role: "",
        birthday: null,
        joiningDate: null
    });
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const emailHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "")?.toLowerCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const passwordHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "");
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                setRoles(response.data);
                setSelectedRole(null);
                setTrigger(prev => prev + 1)
            }).catch((error) => {
                console.error("fetch roles error >", error);
                setRoles([]);
            });
        } else {
            setRoles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment]);

    const expectedColumns = ['fullName', 'email', 'birthday', 'joiningDate', 'password', 'gender'];

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType =
            file?.name.split('.').pop().toLowerCase();
        if (fileType !== 'csv') {
            setFileError('Please upload a CSV file.');
            return;
        }
        Papa.parse(file, {
            complete: function (results) {
                const csvHeader = results.data[0]; // The first row contains the header
                const dataRows = results.data.slice(1); // Remaining rows contain the data
                if (!dataRows) {
                    setFileError("No data available in the CSV")
                    return;
                }
                const filteredData = dataRows.filter(row => row.every(field => field?.trim() !== ''));
                const missingColumns = expectedColumns.filter(col => !csvHeader.includes(col));

                if (missingColumns.length > 0) {
                    setFileError(`Missing columns in CSV: ${missingColumns.join(", ")}`);
                    return;
                }

                const formattedData = filteredData.map(row => {
                    let rowObject = {};
                    csvHeader.forEach((header, index) => {
                        rowObject[header] = row[index];
                    });
                    return rowObject;
                });
                setFile(formattedData);
                setFileError("");
                setOpenBulkUpload(true)
            },
            error: function (error) {
                console.error("Error parsing CSV:", error);
                setFileError('Error parsing CSV file.');
            }
        });
    };


    const handleSubmit = () => {
        if (checkEmptyFields(formInput)) {
            setError("Field must not be empty");
        } else if (!validateEmail(formInput.email)) {
            setError("Email is invalid");
        } else if (!validatePassword(formInput.password)) {
            setError("Password should contain at least 8 characters and must contain one uppercase, one lowercase, one digit, and one special character!");
        } else {
            PostRequest(`${process.env.REACT_APP_URL}/user/employee/${accountInfo?.data._id}`, formInput).then((response) => {
                setFormInput({
                    fullName: "",
                    email: "",
                    password: "",
                    gender: "prefer not to say",
                    department: "",
                    role: ""
                })
                setSuccess({
                    singleUpload: "Employee added successfully",
                    bulkUpload: ""
                });
                setSelectedDepartment(null)
                setSelectedRole(null)
                // setOpen(false)
                setError("")
                setTrigger(prev => prev + 1)
                dispatch(subscriptionActions.setPlanTrigger(planTrigger + 1))
            }).catch((error) => {
                console.log(error)
                setError(error.data)
            })
        }
    }

    const handleUpload = () => {
        if (file.length === 0) {
            setFileError("Add a valid CSV")

        } else {
            const missingDepartment = file.some((item) => !item.department);
            const missingRole = file.some((item) => !item.role);
            const invalidEmail = file.some((item) => !validateEmail(item.email));
            const invalidPassword = file.some((item) => !validatePassword(item.password));

            if (missingDepartment) {
                setFileError("Each employee must have a department selected.");
            } else if (missingRole) {
                setFileError("Each employee must have a role selected.");
            } else if (invalidEmail) {
                setFileError("Each employee's email must be valid");
            } else if (invalidPassword) {
                setFileError("Each employee's password must be at least 8 characters and must contain one uppercase, one lowercase, one digit, and one special character!");
            } else {
                const updatedData = file.map((item) => {
                    const updatedItem = { ...item };
                    if (item.birthday) {
                        updatedItem.birthday = convertDate(item.birthday);
                    }
                    if (item.joiningDate) {
                        updatedItem.joiningDate = convertDate(item.joiningDate);
                    }
                    return updatedItem;
                });
                PostRequest(`${process.env.REACT_APP_URL}/user/upload/${accountInfo.data._id}`, { data: updatedData }).then((response) => {
                    setSuccess({
                        singleUpload: "",
                        bulkUpload: "Employees added successfully"
                    });
                    setFile([])
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    setOpenBulkUpload(true)
                    setTrigger(prev => prev + 1)
                    dispatch(subscriptionActions.setPlanTrigger(planTrigger + 1))
                }).catch((error) => {
                    console.error('Error uploading file:', error);
                    setFileError(error?.data || 'Unknown error occurred');
                })
            }
        }
    };

    const handleDownloadSampleCSV = () => {
        const sampleData = [
            expectedColumns,
            ["demouser", "example@gmail.com", "01/01/2001", "01/01/2024", "user@123", "male"]
        ]; const csvContent = Papa.unparse(sampleData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Employee_Sample.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClose = () => {
        setFormInput({
            fullName: "",
            email: "",
            password: "",
            gender: "prefer not to say",
            department: "",
            role: ""
        });
        setSuccess({
            singleUpload: "",
            bulkUpload: ""
        })
        setSelectedDepartment(null)
        setSelectedRole(null)
        setOpen(false)
        setError("")
        setFileError("")
        setFile([])
    }

    let currentdate = formatDateForCompare(new Date());
    const isExpired = formatDateForCompare(currentPlan.expiryOn) < currentdate;

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            handleSubmit()
        }
    };

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className={className}
                disabled={isAdmin ? !isAdmin : !accountInfo?.permissions?.addEmployee}
            >{label}</button>
            <ReactModal open={open} close={handleClose} maxWidth="600px" heading={!isExpired && departments.length > 0 && "Add Employee"}>
                {isExpired ? (
                    <ExpiredPlan />
                ) : (
                    departments.length > 0 ? (
                        <>
                            {file.length > 0 &&
                                <BulkEmployeeUpload
                                    open={openBulkUpload}
                                    setOpenBulkUpload={setOpenBulkUpload}
                                    data={file}
                                    departments={departments}
                                    setFile={setFile}
                                    error={error}
                                />
                            }
                            <div className='flex gap-2 items-center border-b mb-[12px]'>
                                <div className='text-[14px] font-semibold text-[#aaa]'>Single Upload</div>
                                <FaUserTie />
                            </div>
                            {error && <Error message={error} />}
                            {success && success.singleUpload &&
                                <div className={`text-[#228B22]`}>{success.singleUpload}</div>

                            }
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-[10px]'>
                                <div>
                                    <Label title='Full Name' isImportant />
                                    <TextInput
                                        name="fullName"
                                        value={formInput.fullName || ""}
                                        onChange={nameHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Email' isImportant />
                                    <TextInput
                                        name="email"
                                        value={formInput.email || ""}
                                        onChange={emailHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Birthday' isImportant />
                                    <TextInput
                                        name="birthday"
                                        type='date'
                                        value={formInput.birthday}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            const [year, month, day] = value.split(/[-T]/);

                                            if (year.length > 4) {
                                                const validYear = year.slice(0, 4);
                                                const newValue = `${validYear}-${month}-${day}${value.includes('T') ? 'T' + value.split('T')[1] : ''}`;
                                                setFormInput((prevInput) => ({
                                                    ...prevInput,
                                                    [name]: newValue,
                                                }));
                                                return;
                                            }
                                        }}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div className='relative'>
                                    <Label title='Password' isImportant />
                                    <TextInput
                                        name="password"
                                        type={showPass ? "text" : "password"}
                                        value={formInput.password || ""}
                                        onChange={passwordHandler}
                                        className='mt-[5px]'
                                        placeholder="********"
                                        onKeyDown={handleKeyDown}
                                    />
                                    {showPass ? (
                                        <button onClick={() => setShowPass(false)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                                            <FaRegEyeSlash size={15} title="Hide" />
                                        </button>
                                    ) : (
                                        <button onClick={() => setShowPass(true)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                                            <FaRegEye size={15} title="Show" />
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <Label title='Department' isImportant />
                                    <Dropdown
                                        value={selectedDepartment ? selectedDepartment.title : ""}
                                        onChange={(option) => {
                                            setSelectedDepartment(option);
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                department: option._id
                                            }));
                                        }}
                                        options={departments}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Designation' isImportant />
                                    <Dropdown
                                        value={selectedRole ? selectedRole.title : ""}
                                        onChange={(option) => {
                                            setSelectedRole(option);
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                role: option._id
                                            }))
                                        }}
                                        options={roles}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Date of Joining' isImportant />
                                    <TextInput
                                        name="joiningDate"
                                        type='date'
                                        value={formInput.joiningDate}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            const [year, month, day] = value.split(/[-T]/);

                                            if (year.length > 4) {
                                                const validYear = year.slice(0, 4);
                                                const newValue = `${validYear}-${month}-${day}${value.includes('T') ? 'T' + value.split('T')[1] : ''}`;
                                                setFormInput((prevInput) => ({
                                                    ...prevInput,
                                                    [name]: newValue,
                                                }));
                                                return;
                                            }
                                        }}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Gender' isImportant />
                                    <Gender
                                        value={formInput.gender}
                                        onClick={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button className='w-[300px] border mt-[20px] h-[40px] bg-strawberry text-white rounded-full ' onClick={handleSubmit}>Create</button>
                            </div>
                            <div className='text-center my-[20px] text-[14px] font-semibold text-[#aaa]'>
                                ---------- OR ----------
                            </div>
                            <div className='my-[30px] relative'>
                                <div className='flex gap-2 items-center border-b'>
                                    <div className='text-[14px] font-semibold text-[#aaa] '>Bulk Upload</div>
                                    <FaFileUpload />
                                </div>
                                {fileError && <Error message={fileError} />}
                                {success && success.bulkUpload &&
                                    <div className={`text-[#228B22]`}>{success.bulkUpload}</div>

                                }
                                <div className='flex justify-between items-center'>
                                    <input type="file" accept=".csv" ref={fileInputRef} onChange={handleFileChange} className='mt-[15px]' />
                                    {file.length > 0 && (
                                        <RiEditFill
                                            className='mt-3'
                                            onClick={() => setOpenBulkUpload(true)}
                                            style={{ cursor: 'pointer' }}
                                            title="Edit Bulk Upload"
                                        />
                                    )
                                    }
                                    <button
                                        onClick={handleDownloadSampleCSV}
                                        className=" top-3 right-0 mt-4 mr-2 text-blue-500 underline"
                                    >
                                        Download Sample CSV
                                    </button>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button className='w-[300px] border mt-[20px] h-[40px] bg-strawberry text-white rounded-full' onClick={handleUpload}>Upload</button>
                            </div>
                        </>
                    ) : (
                        <div className='px-[10px] py-[40px]'>
                            <LiaCatSolid className='mx-auto' size={60} />
                            <h4 className='text-center w-full'>Oops! Can not add Employee!</h4>
                            <p className='text-center w-full mt-[10px]'>Add Department and Designation first</p>
                        </div>
                    )
                )}
            </ReactModal>
        </>
    );
};

export default AddEmployee;
