import React from 'react'
import Checkbox from '../custom/checkbox';
import Pagination from '../custom/pagination';

const UsersList = ({setSelectedUser=()=>{}, ...props}) => {
    return (
        <div className='max-w-[600px]'>
            <Pagination
                itemsPerPage={10}
                data={props.data}
                type='table'
                position='left'
                renderComponent={(currentItems) => {
                    return (
                        <UsersData 
                            users={currentItems} 
                            setSelectedUser={setSelectedUser} 
                            {...props}  
                        />
                    )
                }}
            />
        </div>
    )
}

function UsersData({users, setSelectedUser=()=>{}, ...props}) {
    return (
        <table className='mt-[20px] relative'>
            <thead>
                <tr className='bg-[#dcdcdc] py-[120px]'>
                    {columns.map((col, i) => (
                        <td
                            key={i}
                            style={{ width: col.width, textAlign: col.textAlign}}
                            className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                        >{col.header}</td>
                    ))}
                    <td className='text-left'></td>
                </tr>
            </thead>
            <tbody>
                {users.map((item, i) => (
                    <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{i+1 || "----"}</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item.fullName || "----"}</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>{item.role?.title}</td>
                        <td className='text-left text-[14px] px-[10px] py-[5px]'>
                            <Checkbox
                                id="editMilestone"
                                checked={props.selectedUser?._id === item._id}
                                onChange={() => {
                                    if (props.selectedUser?._id === item._id) setSelectedUser(null)
                                        else setSelectedUser(item)
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const columns = [
    { 
        header: 'S.No.', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'Full Name', 
        width: '40%',
        textAlign: "left"
    },
    { 
        header: 'Role', 
        width: '40%',
        textAlign: "left"
    },
    { 
        header: '', 
        width: '10%',
        textAlign: "left"
    }
]

export default UsersList;