import React, { useEffect, useState } from 'react';
import ReactModal from '.';
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import Dropdown from '../components/custom/dropdown';
import Gender from '../components/shared/gender';
import { checkEmptyFields, validateEmail } from '../utils/formValidation';
import Error from '../components/custom/error';
import { DeleteRequest, GetRequest, PutRequest } from '../utils/request';
import { useSelector, useDispatch } from 'react-redux';
import { employeeActions } from '../store/employee-slice';
import DeleteConfirmation from './deleteConfirmation';
import Button from '../components/custom/button';
import Loader from '../components/custom/loader';
import { formatDateForCompare } from '../utils/formatTimestamp';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';

const UpdateEmployee = ({ setTrigger=()=>{}, departments=[] }) => {
    const dispatch = useDispatch();
    const { updateEmployee } = useSelector((state) => state.employee);
    const { accountInfo } = useSelector((state) => state.account);

    const selectedDepart = departments.find(index => index._id === updateEmployee?.role?.departmentId);

    const [error, setError] = useState("");
    const [roles, setRoles] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState(selectedDepart);
    const [selectedRole, setSelectedRole] = useState(updateEmployee?.role || null);
    const [showPass, setShowPass] = useState(false);

    const [formInput, setFormInput] = useState({
        fullName: updateEmployee?.fullName,
        email: updateEmployee?.email,
        gender: updateEmployee?.gender,
        role: updateEmployee?.role?._id,
        birthday: updateEmployee?.birthday ? formatDateForCompare(updateEmployee.birthday) : "",
        joiningDate: updateEmployee?.joiningDate ? formatDateForCompare(updateEmployee.joiningDate) : "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "birthday" || name === "joiningDate") {
            const [year, month, day] = value.split("-");
            if (year && year.length > 4) {
                return;
            }
            if ((month && month.length > 2) || (day && day.length > 2)) {
                return;
            }
        }
        setFormInput((prevState) => ({
            ...prevState,
            [name]: value
        }));
        setError("");
    };

    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const emailHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "")?.toLowerCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                setRoles(response.data);
                const role = response.data.length > 0 && response.data.find(index => index._id === updateEmployee?.role?._id)
                setSelectedRole(role);
                setTrigger(prev => prev + 1)
            }).catch((error) => {
                console.error("roles error >", error);
                setRoles([]);
            });
        } else {
            setRoles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment]);

    const updateHandler = () => {
        setDisabled(true)
        setError("")
        if (checkEmptyFields(formInput)) {
            setError("Field must not be empty!")
            setDisabled(false)
        }else if(!validateEmail(formInput.email)) {
            setError("Invalid Email!")
            setDisabled(false)
        }else {
            PutRequest(`${process.env.REACT_APP_URL}/user/${updateEmployee?._id}?adminId=${accountInfo?.data._id}`, formInput).then((response) => {
                setDisabled(false)
                setTrigger(prev => prev+1)
                dispatch(employeeActions.setUpdateEmployee(null))
            }).catch((error) => {
                console.log(error)
                setError(error.data)
                setDisabled(false)
            })
        }
    }

    const handleClose = () => {
        setFormInput({
            fullName: "",
            email: "",
            password: "",
            gender: "prefer not to say",
            department: "",
            role: "",
        })
        dispatch(employeeActions.setUpdateEmployee(null))
        setError("")
    }

    function deleteHandler(id){
        DeleteRequest(`${process.env.REACT_APP_URL}/user/${id}?userId=${accountInfo?.data?._id}`).then(response => {
            dispatch(employeeActions.setUpdateEmployee(null))
            setTrigger(prev => prev+1)
        }).catch(error => {
            console.log("delete error", error)
            setError("Something went wrong.")
        })
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            updateHandler()
        }
    };
    
    return (
        <ReactModal open={updateEmployee} close={handleClose} maxWidth="600px" heading="Update Employee">
            {disabled && (
                <Loader />
            )}
            {error && <Error message={error} />}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-[10px]'>
                <div>
                    <Label title='Full Name' isImportant />
                    <TextInput
                        name="fullName"
                        value={formInput.fullName || ""}
                        onChange={nameHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Email' isImportant />
                    <TextInput
                        name="email"
                        value={formInput.email || ""}
                        onChange={emailHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Birthday'/>
                    <TextInput 
                        name="birthday"
                        type='date'
                        value={formInput.birthday}
                        onChange={handleChange}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='relative'>
                    <Label title='Password' />
                    <TextInput
                        name="password"
                        value="********"
                        className='mt-[5px]'
                        disabled
                        onKeyDown={handleKeyDown}
                    />
                    {showPass ? (
                        <button disabled onClick={() => setShowPass(false)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                            <FaRegEyeSlash size={15} title="Hide" color="#aaa" />
                        </button>
                    ) : (
                        <button disabled onClick={() => setShowPass(true)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                            <FaRegEye size={15} title="Show" color="#aaa" />
                        </button>
                    )}
                </div>
                <div>
                    <Label title='Department' isImportant />
                    <Dropdown
                        value={selectedDepartment ? selectedDepartment.title : ""}
                        onChange={(option) => {
                            setSelectedDepartment(option);
                            setFormInput((prevState) => ({
                                ...prevState,
                                department: option._id
                            }));
                        }}
                        options={departments}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Designation' isImportant />
                    <Dropdown
                        value={selectedRole ? selectedRole.title : ""}
                        onChange={(option) => {
                            setSelectedRole(option);
                            setFormInput((prevState) => ({
                                ...prevState,
                                role: option._id
                            }))
                        }}
                        options={roles}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Date of Joining'/>
                    <TextInput 
                        name="joiningDate"
                        type='date'
                        value={formInput.joiningDate}
                        onChange={handleChange}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Gender' isImportant />
                    <Gender value={formInput.gender} onClick={handleChange}  onKeyDown={handleKeyDown}/>
                </div>
            </div>
            <div className='flex justify-center gap-[10px] mt-[40px]'>
                <DeleteConfirmation
                    onClick={() => deleteHandler(updateEmployee?._id)}
                    className='max-w-[120px]'
                    close={() => dispatch(employeeActions.setUpdateEmployee(null))}
                />
                <Button
                    label='Update'
                    onClick={updateHandler}
                    type='secondary'
                />
            </div>
        </ReactModal>
    );
};

export default UpdateEmployee;