import React, { useEffect, useState } from 'react'
import ReactModal from '.';
import Loader from '../components/custom/loader';
import Button from '../components/custom/button';
import Error from '../components/custom/error';
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import { FiEdit2 } from 'react-icons/fi';
import DeleteConfirmation from './deleteConfirmation';
import { DeleteRequest, PostRequest, PutRequest } from '../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import Checkbox from '../components/custom/checkbox';
import { employeeActions } from '../store/employee-slice';
import { FaCheck } from "react-icons/fa";
import { chatActions } from '../store/chat-slice';

const DepartmentUpdate = ({data, open=false, close=()=> {}, setSelected=()=>{}, setTrigger=()=>{}, setRoleTrigger=()=>{}, ...props }) => {
    const dispatch = useDispatch();
    const { triggerChat } = useSelector((state) => state.chat);
    const [ title, setTitle ] = useState("");
    const [ error, setError ] = useState("");
    const [ disabled, setDisabled ] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [edit, setEdit] = useState({
        show: false,
        value: "",
        data: null
    });

    const nameHandler = (e) => {
        const { value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setTitle(cleanedValue)
        setError("");
    };

    useEffect(() => {
        if (data){
            setTitle(data.title)
        }
    },[open, data])

    function deleteHandler(){
        setError("");
        setDisabled(true);
        DeleteRequest(`${process.env.REACT_APP_URL}/department/${data._id}`).then(response => {
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            setTrigger(prev => prev+1)
            closeHandler()
            dispatch(employeeActions.setRoles([]))
            dispatch(uiActions.setPaginationOffset(0))
            dispatch(employeeActions.setSelectedDepartment(null))
            setSelected({department: null})
        }).catch(error => {
            console.log("delete activity error >>>", error.data);
            setError("Deletion failed!");
            setDisabled(false);
            close()
        });
    }

    function closeHandler(){
        close()
        setError("");
        setDisabled(false);
        setSelected({department: null})
    }

    function deleteRoleHandler() {
        PostRequest(`${process.env.REACT_APP_URL}/department/role/delete`, {userIds: selectedRoles}).then(response => {
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            setRoleTrigger(prev => prev+1)
            setSelectedRoles([])
            dispatch(employeeActions.setSelectedRole(null))
            dispatch(employeeActions.setSelectedDepartment(null))
            dispatch(uiActions.setPaginationOffset(0))
        }).catch(error => {
            console.log("delete activity error >>>", error.data);
            setError(error.data || "Updation failed!");
            setDisabled(false);
        });
    }
    function updateHandler(){
        setError("");
        setDisabled(true);
        if (!title){
            setError("Field must not be empty!");
            setDisabled(false);
        }else{
            PutRequest(`${process.env.REACT_APP_URL}/department/${data._id}`, { title }).then(response => {
                setTrigger(prev => prev+1)
                dispatch(employeeActions.setSelectedDepartment(response.data))
                closeHandler()
                dispatch(uiActions.setPaginationOffset(0))
            }).catch(error => {
                console.log("delete activity error >>>", error.data);
                setError(error.data || "Updation failed!");
                setDisabled(false);
            });
        }
    }
    function updateRoleHandler(){
        setError("");
        if (!edit.value) {
            setError("Field must not be empty!");
        }else if (edit.title === edit.value){
            setDisabled(false);
            setEdit({show: false, data: "", value: ""})
        }else{
            PutRequest(`${process.env.REACT_APP_URL}/department/role/${edit.data._id}`, { title: edit.value }).then(response => {
                setRoleTrigger(prev => prev+1)
                setEdit({show: false, data: null, value: ""})
                dispatch(uiActions.setPaginationOffset(0))
            }).catch(error => {
                console.log("delete activity error >>>", error.data);
                setError(error.data || "Updation failed!");
                setDisabled(false);
            });
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            updateHandler()
        }
    };
           
    return (
        <>
            <button onClick={close} className='absolute right-[10px] top-[-15px] h-[25px] w-[25px] bg-white rounded-full flex justify-center items-center border'>
                <FiEdit2 size={12} />
            </button>
            <ReactModal open={open} close={closeHandler} maxWidth="600px" heading='Update Department' padding='20px'>
                {disabled && <Loader />}
                <Error message={error} />
                <div className="w-full">
                    <Label title='Department Title' />
                    <TextInput 
                        value={title}
                        name="title"
                        onChange={nameHandler}
                        maxLength={30}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {props.roles.length > 0 && (
                    <div className='mt-[20px]'>
                        <div className='flex justify-between items-center'>
                            <h6 className='mb-[10px]'>Designations</h6>
                            {selectedRoles.length > 0 && (
                                <DeleteConfirmation 
                                    onClick={deleteRoleHandler} 
                                    component={
                                        <div className='flex items-center gap-[5px]'>
                                            <AiTwotoneDelete color='#FF0000' /> Delete {selectedRoles.length} {selectedRoles.length === 1 ? "designation" : "designations"}
                                        </div>
                                    }
                                    className='text-error w-full max-w-[200px] !h-[35px]'
                                />
                            )}
                        </div>
                        {props.roles.map((item, i) => (
                            <div key={i} className='flex px-[10px] gap-[30px] justify-between items-center py-[5px] border-b'>
                                {edit.show && edit.data.title === item.title ? (
                                    <div className='relative w-full border'>
                                        <input 
                                            value={edit.value}
                                            className='px-[8px] pr-[20px] w-full'
                                            maxLength={30}
                                            onChange={(e) => setEdit(prevState => ({...prevState, value: e.target.value}))}
                                        />
                                        <FaCheck
                                            color='#008000' 
                                            title="Update"
                                            className='absolute right-[10px] top-[4px] cursor-pointer' 
                                            onClick={updateRoleHandler}
                                        />
                                    </div>
                                ):(
                                    <span className='text-[12px]'>{item.title}</span>
                                )}
                                <div className='flex justify-end items-center gap-[10px]'>
                                    <AiTwotoneEdit 
                                        title="Edit"
                                        onClick={() => setEdit({show: true, value: item.title, data: item})} 
                                        className='cursor-pointer' 
                                    />
                                    <Checkbox
                                        checked={selectedRoles.includes(item._id)}
                                        onChange={() => {
                                            if (selectedRoles?.includes(item._id)){
                                                const filtered = selectedRoles.filter(index => index !== item._id)
                                                setSelectedRoles(filtered)
                                            }else{
                                                setSelectedRoles([...selectedRoles, item._id])
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className='flex justify-center gap-[10px] mt-[40px]'>
                    <DeleteConfirmation
                        onClick={deleteHandler}
                        className='max-w-[120px]'
                        close={close}
                        description={`Are you sure you want to delete department "${data?.title}"`}
                    />
                    <Button
                        label='Update'
                        onClick={updateHandler}
                        type='secondary'
                    />
                </div>
            </ReactModal>
        </>
    )
}

export default DepartmentUpdate;