import React from 'react'
import { useDispatch } from 'react-redux';
import { employeeActions } from '../../store/employee-slice';
import { FiEdit2 } from 'react-icons/fi';
import { uiActions } from '../../store/ui-slice';

const EmployeeFilter = ({setOpen=()=>{}, setSelected=()=>{}, ...props}) => {
    const dispatch = useDispatch();
    return (
        <div className='mt-[50px] mb-[50px]'>
            <div className='flex flex-wrap gap-[30px]'>
                <div className='relative w-full mb-[10px]'>
                    <h5 title='Department'>Departments</h5>
                    <div className='mt-[15px] w-full'>
                        <div className="w-full flex flex-wrap gap-[10px]">
                            <button
                                className={`text-[12px] px-[20px] py-[5px] bg-[#594cda] rounded-full border cursor-pointer ${!props.selectedDepartment ? "bg-strawberry text-[#fff]" : "bg-white text-black"}`}
                                onClick={() => {
                                    dispatch(employeeActions.setSelectedDepartment(null));
                                    dispatch(employeeActions.setSelectedRole(null));
                                    dispatch(employeeActions.setFilteredResults(props.employees))
                                    dispatch(uiActions.setPaginationOffset(0))
                                }}
                            >All</button>
                            {props.departments.map((item, i) => (
                                <button
                                    key={i}
                                    className={`text-[12px] px-[20px] py-[5px] bg-[#594cda] rounded-full border cursor-pointer capitalize ${props.selectedDepartment?._id === item._id ? "bg-strawberry text-[#fff]" : "bg-white text-black"}`}
                                    onClick={() => {
                                        dispatch(employeeActions.setSelectedDepartment(item));
                                        dispatch(employeeActions.setFilteredResults(props.employees));
                                        dispatch(uiActions.setPaginationOffset(0))
                                    }}
                                >{item.title}</button>
                            ))}
                        </div>
                    </div>
                </div>
                {props.selectedDepartment && (
                    <div className='relative group w-full'>
                        <div className='!inline-flex justify-start'>
                            <h5 title='Designation' className='capitalize'>{props.selectedDepartment?.title} 
                            </h5>
                            <button 
                                onClick={() => {
                                    setOpen(true)
                                    setSelected(prevState => ({...prevState, department: props.selectedDepartment}));
                                }} 
                                className='flex h-[25px] w-[25px] justify-center items-center ml-[3px]'
                            >
                                <FiEdit2 size={12} />
                            </button>
                        </div>
                        <div className='mt-[15px] w-full'>
                            <div className="w-full flex flex-wrap gap-[10px]">
                                <button
                                    className={`text-[12px] px-[20px] py-[5px] bg-[#594cda] rounded-full border cursor-pointer ${(!props.selectedDepartment && !props.selectedRole) || (props.selectedDepartment && !props.selectedRole) ? "bg-strawberry text-[#fff]" : "bg-white text-black"}`}
                                    onClick={() => {
                                        dispatch(employeeActions.setSelectedRole(null));
                                        dispatch(employeeActions.setFilteredResults(props.employees))
                                        dispatch(uiActions.setPaginationOffset(0))
                                    }}
                                >All</button>
                                {props.roles.map((item, j) => (
                                    <button 
                                        key={j} 
                                        className={`text-[12px] px-[20px] py-[5px] bg-[#594cda] rounded-full border cursor-pointer capitalize ${(props.selectedRole && props.selectedRole?._id === item._id) ? "bg-strawberry text-[#fff]" : "bg-white text-black"}`}
                                        onClick={() => {
                                            dispatch(employeeActions.setSelectedRole(item));
                                            dispatch(employeeActions.setFilteredResults(props.employees))
                                            dispatch(uiActions.setPaginationOffset(0))
                                        }}
                                    >{item.title}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EmployeeFilter;