import React, { useEffect, useState } from 'react';
import Avatar from '../../components/custom/avatar';
import Department from '../../popups/department';
import Designation from '../../popups/designation'
import { useDispatch, useSelector } from 'react-redux';
import { GetRequest } from '../../utils/request';
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { formatDate } from '../../utils/formatTimestamp';
import isBirthdayToday from '../../utils/isBirthdayToday';
import IconButton from '../../components/custom/iconButton';
import AddEmployee from '../../popups/addEmployee';
import Search from '../../components/custom/search';
import Layout from '../../layout';
import { employeeActions } from '../../store/employee-slice';
import EmployeeProfile from '../../popups/employeeProfile';
import { GrFormEdit } from "react-icons/gr";
import UpdateEmployee from '../../popups/updateEmployee';
import DepartmentUpdate from '../../popups/departmentUpdate';
import Pagination from '../../components/custom/pagination';
import EmployeeFilter from '../../components/employee/filter';
import { uiActions } from '../../store/ui-slice';
import { TbCalendarUser } from "react-icons/tb";

const Employees = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { updateEmployee, viewEmployee } = useSelector((state) => state.employee);
    const { 
        employees, 
        filteredResults, 
        departments, 
        roles, 
        selectedDepartment, 
        selectedRole,
        searchKey
    } = useSelector((state) => state.employee);
    const [loader, setLoader] = useState(true);
    const [searchBirthday, setSearchBirthday] = useState(false);  
    const [trigger, setTrigger] = useState(false);
    const [roleTrigger, setRoleTrigger] = useState(0)
    const [ open, setOpen ] = useState(false);
    const [selected, setSelected] = useState({
        department: null
    })

    useEffect(() => { 
        if (accountInfo){
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInfo, trigger])

    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                dispatch(employeeActions.setRoles(response.data));
                dispatch(employeeActions.setSelectedRole(null));
            }).catch((error) => {
                console.log("roles error >", error);
                dispatch(employeeActions.setRoles([]));
            });
        } else {
            dispatch(employeeActions.setRoles([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment, roleTrigger]);

    useEffect(() => {
        if (accountInfo){
            GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setEmployees(response.data))
                dispatch(employeeActions.setFilteredResults(response.data))
                setLoader(false)
            }).catch((error) => {
                setLoader(false)
                console.log(" employee error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInfo, trigger])

    // FILTER DATA THROUGH SEARCH BAR AND KEY
    useEffect(() => {
        if (searchKey.length > 0){
            const filtered = employees.filter(index => {
                return index.fullName?.toLowerCase().includes(searchKey?.toLowerCase()) || index.email?.toLowerCase().includes(searchKey?.toLowerCase())
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        }else{
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[employees, searchKey]);

    useEffect(() => {
        if (selectedRole){
            const filtered = employees.filter(index => {
                return index.role?._id === selectedRole?._id;
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        } else if (selectedDepartment) {
            const filtered = employees.filter(index => {
                return index.role?.departmentId === selectedDepartment?._id;
            });
            dispatch(employeeActions.setFilteredResults(filtered))
        } else {
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[employees, selectedRole, selectedDepartment]);

    useEffect(() => {
        if (searchBirthday){
            const filtered = employees.filter(index => {
                return isBirthdayToday(index.birthday);
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        }else{
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchBirthday, employees]);

    return (
        <Layout>
            {updateEmployee && (
                <UpdateEmployee setTrigger={setTrigger} departments={departments} />
            )}
            {viewEmployee && (
                <EmployeeProfile />
            )}
            <div className='flex max-md:flex-wrap pt-[30px] lg:pt-[50px]'>
                <Department label="Add Department" className='border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-[#fff0f5] z-[3]' setTrigger={setTrigger} />
                <Designation label="Add Designation" className='border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-white z-[2]' setTrigger={setTrigger} />
                <AddEmployee 
                    label="Add Employee" 
                    className='border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-[#f5f5dc] z-[1]' 
                    departments={departments} 
                    setTrigger={setTrigger}
                />
            </div>
            <DepartmentUpdate 
                data={selected.department} 
                open={open}
                close={() => setOpen(false)}
                setSelected={setSelected}
                setTrigger={setTrigger}
                roles={roles}
                setRoleTrigger={setRoleTrigger}
            />
            <div className='mt-[50px] mb-[50px]'>

                <EmployeeFilter 
                    departments={departments} 
                    roles={roles}
                    selectedRole={selectedRole}
                    selectedDepartment={selectedDepartment}
                    employees={employees}
                    setOpen={setOpen}
                    setSelected={setSelected}
                />

                <div className='flex gap-[10px] flex-col-reverse lg:flex-row justify-between item-center mt-[10px] mb-[30px]'>
                    <div className='flex gap-[10px]'>
                        <h3 className='my-auto'>Employees</h3>
                        {/* <img src={kitty} alt="kitty" className='max-w-[80px] w-full h-auto' /> */}
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center gap-[30px]'>
                <button 
                    onClick={() => {
                        setSearchBirthday(prev => !prev);
                        dispatch(uiActions.setPaginationOffset(0));
                    }} 
                    className={`w-[80px] border bg-white rounded-full flex ${searchBirthday ? "justify-end" : "justify-start"}`}
                >
                    <IconButton 
                        icon={<LiaBirthdayCakeSolid size={20} color={searchBirthday ? "#880000" : "#aaa"} />}
                        className={`h-[40px] w-[40px] ${searchBirthday ? "bg-[#fff0f5]" : "bg-hover"}`}
                    />
                </button>
                <Search
                    placeholder='I will help you find... :)'
                    value={searchKey}
                    onChange={(e) => {
                        dispatch(employeeActions.setSearchKey(e.target.value))
                        dispatch(uiActions.setPaginationOffset(0))
                    }}
                    className='max-w-[250px]'
                />
            </div>
            {loader ? (
                <div className='text-center mt-[30px]'>Loading...</div>
            ):(
                filteredResults.length > 0 ? (
                    <div className='mb-[50px]'>
                        <Pagination
                            itemsPerPage={12}
                            data={filteredResults}
                            renderComponent={(currentItems) => (
                                <EmployeeData data={currentItems} />
                            )}
                        />
                    </div>
                ):(
                    searchKey.length > 0 ? (
                        <div className='mt-[30px] text-center'>No Employee found!</div>
                    ):(
                        <div className='mt-[30px] text-center'>{searchBirthday ? "No Birthday Today!" : "No Employees added!"}</div>
                    )
                )
            )}
        </Layout>
    );
};

function EmployeeData({data}) {    
    const dispatch = useDispatch();
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] mt-[30px]">
            {data.map((item, i) => (
                <div key={i} className="group relative w-full bg-white border border-gray-200 rounded-lg shadow p-[20px] flex gap-[30px] items-start overflow-hidden">
                    {isBirthdayToday(item.birthday) && (
                        <BirthdayTag />
                    )}
                    <Avatar 
                        src={item.avatar} 
                        alt={item.fullName} 
                        size={20} 
                        className={`h-[50px] min-w-[50px] border-[2px] ${isBirthdayToday(item.birthday) ? "!border-error" : item.gender === "female" ? "!border-[#FF69B4] border-[2px]" : item.gender === "male" ? "!border-[#6ca0dc]" : "border-[#aaa]"}`} 
                        noOnline
                        onClick={() =>  dispatch(employeeActions.setViewEmployee(item)) }
                    />
                    <div>
                        <h5 className="text-xl font-medium">{item.fullName}</h5>
                        <p className="text-[#aaa] break-all">{item.email}</p>
                        <p className="text-[#aaa] capitalize">({item.role?.title})</p>
                        <p className="capitalize flex mt-[5px] items-center"> <TbCalendarUser size={15} title='Date of Joining' className='mr-[5px]' /> {item.joiningDate ? formatDate(item.joiningDate) : "---"}</p>
                        <p className="capitalize flex mt-[5px] text-[#fc0fc0] items-center"> <LiaBirthdayCakeSolid size={15} className='mr-[5px]' /> {item.birthday ? formatDate(item.birthday) : "---"}</p>
                    </div>
                    <div className='absolute top-[5px] right-[5px] group-hover:block hidden'>
                        <IconButton 
                            icon={<GrFormEdit size={20} color='#000' />}
                            className='bg-white h-[30px] w-[30px] border-none'
                            title="Edit"
                            onClick={() => dispatch(employeeActions.setUpdateEmployee(item))}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

function BirthdayTag(){
    return(
        <div className='text-error -rotate-[40deg] bg-[#ffe4e1] px-[21px] absolute top-[10px] left-[-20px] z-[1] shadow-md'>
            Birthday
        </div>
    )
}

export default Employees;